import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query navbarQuery {
      logo: file(relativePath: { eq: "kvh-3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const toggleNavbar = () => {
    let navbarMenu = document.getElementById("navbarMenu");
    let navbarBurger = document.getElementById("navbarBurger");
    if (navbarMenu.classList.contains("is-active")) {
      navbarMenu.classList.remove("is-active");
      navbarBurger.classList.remove("is-active");
    } else {
      navbarMenu.classList.add("is-active");
      navbarBurger.classList.add("is-active");
    }
  };

  return (
    <nav
      className="navbar"
      id="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar__content container">
        <div className="navbar__content__brand navbar-brand">
          <Link className="navbar-item" to="/">
            <Img
              className="absolute-cover"
              fluid={data.logo.childImageSharp.fluid}
              imgStyle={{ objectFit: "contain", minHeight: "100%" }}
              alt="Kiinteistövarainhoito-Yhtiöt"
            />
          </Link>
          <a
            id="navbarBurger"
            className="navbar__content__brand__burger navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={toggleNavbar}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarMenu" className="navbar__content__menu navbar-menu">
          <div className="navbar-start"></div>
          <div className="navbar-end">
            <Link className="navbar-item underline-on-hover" to="/">
              Kiinteistövarainhoito-Yhtiöt
            </Link>
            <Link className="navbar-item underline-on-hover" to="/toimitilat">
              Toimitilat
            </Link>
            <Link className="navbar-item underline-on-hover" to="/turun-kauppapuisto">
              Turun Kauppapuisto
            </Link>
            <Link className="navbar-item underline-on-hover" to="/yrityksen-tarina">
              Yrityksen tarina
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
