import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
const Footer = () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      logo: file(relativePath: { eq: "kvh-4.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <footer className="footer">
      <svg
        className="footer__background is-hidden-mobile"
        width="986"
        height="567"
        viewBox="0 0 986 567"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 290.066 -74.8711)"
          fill="url(#paint0_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 335.289 -74.8711)"
          fill="url(#paint1_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 380.512 -74.8711)"
          fill="url(#paint2_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 425.736 -74.8711)"
          fill="url(#paint3_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 118.616 -91.7197)"
          fill="url(#paint4_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 163.84 -91.7197)"
          fill="url(#paint5_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 209.062 -91.7197)"
          fill="url(#paint6_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 254.286 -91.7197)"
          fill="url(#paint7_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 470.959 -74.8711)"
          fill="url(#paint8_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 516.182 -74.8711)"
          fill="url(#paint9_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 561.404 -74.8711)"
          fill="url(#paint10_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 606.627 -74.8711)"
          fill="url(#paint11_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 651.851 -74.8711)"
          fill="url(#paint12_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 697.073 -74.8711)"
          fill="url(#paint13_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 742.296 -74.8711)"
          fill="url(#paint14_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 787.519 -74.8711)"
          fill="url(#paint15_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 832.742 -74.8711)"
          fill="url(#paint16_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 877.965 -74.8711)"
          fill="url(#paint17_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 923.188 -74.8711)"
          fill="url(#paint18_linear)"
        />
        <rect
          width="18.2881"
          height="887.389"
          transform="matrix(0.913369 0.407134 -0.598874 0.800843 968.41 -74.8711)"
          fill="url(#paint19_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint8_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint9_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint10_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint11_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint12_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint13_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint14_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint15_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint16_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint17_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint18_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
          <linearGradient
            id="paint19_linear"
            x1="9.14404"
            y1="0"
            x2="9.14404"
            y2="887.389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#026C36" />
            <stop offset="0.0001" stopColor="#006632" />
            <stop offset="0.9999" stopColor="#05773D" />
            <stop offset="1" stopColor="#129954" />
          </linearGradient>
        </defs>
      </svg>
      <div className="columns footer-content">
        <div className="column is-5 footer-content__logo">
          <Link to="/">
            <div className="footer-content__logo__wrapper is-relative">
              <Img
                className="absolute-cover"
                fluid={data.logo.childImageSharp.fluid}
                alt="Kiinteistövarainhoito-Yhtiöt"
              />
            </div>
          </Link>
        </div>
        <div className="column is-7 footer-content__main">
          <h4>Yhteystiedot</h4>
          <p>Timo Vastamäki</p>
          <p>timo.vastamaki@kiinteistovarainhoito.fi</p>
          <p>+358 40 5211750</p>
          <br></br>
          <p>Hannele Vastamäki</p>
          <p>hannele.vastamaki@kiinteistovarainhoito.fi</p>
          <p>+358 40 5583181</p>
          <br></br>
          <p>Raisiontori 7, 21200 Raisio</p>
          <br></br>
          <small className="mt">© Kiinteistövarainhoito-Yhtiöt 2021</small>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
