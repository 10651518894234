import React from "react";
import Helmet from "react-helmet";
import Footer from "./Footer";
import Navbar from "./Navbar";

import "./layout.scss";

const Layout = ({ children, title, description }) => {
  return (
    <div className="layout">
      <Helmet>
        <title>{`${title} - Kiinteistövarainhoito-Yhtiöt`}</title>
        <meta
          name="description"
          content={
            description ||
            "Kiinteistövarainhoito-Yhtiöt on operatiivisen liiketoimintansa 1999 aloittanut yhtiöryhmä toimien riskirahoittajana, kiinteistökehittäjänä sekä kiinteistösijoittajana."
          }
        ></meta>
        <meta
          name="google-site-verification"
          content="-EidqNhM0IRI-Ra8UC4zTtfJbdgLKt1VcUVBU_JaKYw"
        ></meta>
      </Helmet>
      <Navbar />
      <div className="layout-body">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
